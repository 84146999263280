import React from "react"
import { useBreadcrumbsBox } from "../../common/Breadcrumbs"
import { Cards } from "../../common/cards"
import { Layout, WrapperMainComponent } from "../../common/Layout"
import { ProductsCollectionLink } from "../../common/ProductsCollectionLink"
import {
  ProductsDescription,
  ProductsDescriptionSEO,
} from "../../common/ProductsDescription"
import { ProductsFaq } from "../../common/ProductsFaq"
import { SEO } from "../../common/SEO"
import { ShortListProduct } from "../../common/ShortListProduct"
import { useDetectDevice } from "../../../lib/useDetectDevice"
import { Wrapper } from "./PageCategories.style"

interface Props {
  pageContext: {
    courteListeProduit: CourteListeProduit
    description: string
    contenuSEO: string
    liens_haut_page: any
    faq: { question: string; reponse: string }[]
    categoryName: string
    data: any
    seoTitle: string
    seoDescription: string
  }
  className?: string
}
type CourteListeProduit = {
  collections_saleor: { saleor_id: string }
  titre: string
}
type SubCategory = {
  backgroundImage: any
  name: any
  slug: string
  description: any
}

export function PageCategoryView({ pageContext, className }: Props) {
  const { data, description, contenuSEO, faq, categoryName, liens_haut_page } =
    pageContext

  const datas = data.listOfSubCategories.map((item: SubCategory) => ({
    Image: {
      url: item.backgroundImage,
      name: item.name,
      alt: item.name,
    },
    Link: process.env.GATSBY_FRONTEND_URL + "/" + item.slug,
    Text: item.description,
  }))
  const isMobile = useDetectDevice()
  const [BreadcrumbsBox, NavigateBackButton] = useBreadcrumbsBox({
    category: data.category,
    productName: categoryName,
  })

  return (
    <Layout className={className}>
      <SEO
        title={data.category?.seoTitle}
        description={data.category?.seoDescription}
        // isCookieYesLoaded={true}
      />
      <BreadcrumbsBox />
      <Wrapper className={isMobile && "mobile"}>
        <ProductsDescription data={description} />
        <ProductsCollectionLink data={liens_haut_page} />
        <Cards data={datas} />
        <NavigateBackButton />
      </Wrapper>
      <Wrapper className={isMobile && "mobile"}>
        <ShortListProduct
          collectionId={
            pageContext.courteListeProduit?.collections_saleor?.saleor_id
          }
          title={pageContext.courteListeProduit?.titre}
        ></ShortListProduct>
      </Wrapper>
      {/* <WrapperDescription> */}
      <ProductsDescriptionSEO data={contenuSEO} />
      {/* </WrapperDescription> */}
      <Wrapper className={isMobile && "mobile"}>
        <ProductsFaq data={faq} />
      </Wrapper>
    </Layout>
  )
}
