import React, { useEffect, useState } from 'react';
import { Up2PayElementView } from './Up2PayElement.view';
import { ErrorMessage } from "../../../../common/Texts"
import { Spinner } from '../../../../common/Spinner';
import axios from 'axios';
import { fixPrice } from '../../../../../helper/fixedPrice';
import { parsePhoneNumber } from 'react-phone-number-input';

interface Props {
    email: string
    orderNumber: string
    total: number
    billingAddress: any
    numberOfItems: number
}

export function Up2PayElementContainer({ email, orderNumber, total, numberOfItems, billingAddress }: Props) {
    const [data, setData] = useState(false)
    const [error, setError] = useState(false)
    const amount = total * 100



    useEffect(() => {
        console.log("mon adresse de facturation == ",billingAddress)
        console.log("parsePhoneNumber(billingAddress.phone) == ",parsePhoneNumber(billingAddress.phone))


        axios({
            method: 'post',
            url: process.env.GATSBY_SALEOR_URL + '/plugins/lingemat.plugins.payments.up2pay/webhooks/calculate_hash/',
            data: {
                montant: Number(fixPrice(amount)).toString(),
                ref: orderNumber,
                email: email,
                numberOfItems: numberOfItems.toString(),
                firstname: billingAddress.firstName,
                lastname: billingAddress.lastName,
                streetAddress1: billingAddress.streetAddress1,
                streetAddress2: billingAddress.streetAddress2,
                postalCode: billingAddress.postalCode,
                city: billingAddress.city,
                countryCode: billingAddress.country.code,
                phone: parsePhoneNumber(billingAddress.phone).nationalNumber,
                phoneCountryCode : parsePhoneNumber(billingAddress.phone).countryCallingCode
            }
        })
            .then(response => {
                console.log(response)
                setData(response.data)
            })
            .catch(err => {
                console.error(err)
                const errorMessage = err.response?.data?.message || err.message || "An unknown error occurred";
                setError(errorMessage);
            })
    }, [])

    if (!data && !error) return <Spinner />
    if (error) return <ErrorMessage>{error}</ErrorMessage>
    return <Up2PayElementView data={data} />
}
