import React from "react"

import {
  Wrapper,
  Title,
  MidleColumn,
} from "./PlanDuSite.style"
import { CommonWrapper } from "../../../common/CommonWrapper"
import { v4 as uuidv4 } from "uuid"
import { DomaineItemS } from "./DomaineItemS"
import { Layout } from "../../../common/Layout"
import { SEO } from "../../../common/SEO"
import { useDetectDevice } from "../../../../lib/useDetectDevice"

interface Props {
  className?: string
  data: Object
}

export function PlanDuSiteView({ className, data }: Props) {
  const isMobile = useDetectDevice()
  return (
    <Layout>
      <SEO
        title="Plan du site"
        description="Retrouvez toutes les catégories Chambre, Table et Cuisine, Salle de Bain, Plage, Enfant et Décoration, présentes sur le site Grandes-Marques.fr."
        // isCookieYesLoaded={true}
      />
      <Wrapper className={className}>
        <CommonWrapper>
          {isMobile && (
            <Wrapper>
              <Title>PLAN DU SITE</Title>
              <MidleColumn>
                {data.map((item: any, index: number, array: Array<any>) => {
                  var node = {}
                  if (item.category) node = item.category
                  return (
                    <DomaineItemS
                      first={index == 0}
                      last={index >= array.length - 1}
                      data={node}
                      key={uuidv4()}
                    />
                  )
                })}
              </MidleColumn>
            </Wrapper>
          )}
          {!isMobile && (
            <Wrapper>
              <Title>PLAN DU SITE</Title>
              <MidleColumn>
                {data.map((item: any, index: number, array: Array<any>) => {
                  var node = {}
                  if (item.category) node = item.category
                  return (
                    <DomaineItemS
                      first={index == 0}
                      last={index >= array.length - 1}
                      data={node}
                      key={uuidv4()}
                    />
                  )
                })}
              </MidleColumn>
            </Wrapper>
          )}
        </CommonWrapper>
      </Wrapper>
    </Layout>
  )
}
