import React from "react"
import { useDetectDevice } from "./../../../lib/useDetectDevice"

import {
  Card,
  Wrapper,
  Flex,
  WraperSliderMarque,
  Title,
  MobileCard,
  Column,
  BlogComponent,
  Post,
  ImagePost,
} from "./Homepage.style"
import { v4 as uuidv4 } from "uuid"
import { Layout } from "../../common/Layout"
import { CommonWrapper } from "../../common/CommonWrapper"
import { Slider } from "../../common/Slider"
import { SEO } from "../../common/SEO"
import { useQuerySEO } from "../../common/SEO/SEO.query"
import { BlockSalesArgument } from "./BlockSalesArgument/BlockSalesArgument"
import { ShortListProduct } from "../../common/ShortListProduct"
import { ColoredLine } from "../../common/ColorLine"
import { Link } from "../../common/Link"

interface Props {
  className?: string
  data?: any
}

export function HomepageView({ data, className }: Props) {
  const isMobile = useDetectDevice()

  const strapiGmHomePage = data.strapiGmHomePage

  var images: { url: any; name: any; link: string }[] = []
  strapiGmHomePage.CarouselTendance.ImageLienTexte.map(item => {
    var image = {
      Image: {
        // url: item.Image?.url,
        localFile: item.Image?.localFile,
        name: item.Text,
        alt: item.Image?.alternativeText,
      },
      Link: item.Link ? item.Link : "",
    }
    if (image?.Image?.localFile) {
      images.push(image)
    }
  })

  var dataSEO = useQuerySEO()
  var dataTitle = dataSEO.strapiGmHeader.TagSEO.filter(
    item => item.Name == "og:title"
  )
  dataTitle[0].Property ? dataTitle[0].Property : "Accueil"
  var dataDescription = dataSEO.strapiGmHeader.TagSEO.filter(
    item => item.Name == "og:description"
  )
  dataDescription[0].Property ? dataDescription[0].Property : ""

  return (
    <Layout>
      <SEO
        title={dataTitle[0].Property}
        description={dataDescription[0].Property}
        // isCookieYesLoaded={true}
      />
      <CommonWrapper>
        {isMobile && (
          <Wrapper className={className}>
            <Slider
              data={strapiGmHomePage.CarouselMarketing.ImageLien}
              numberOfCards={1}
              size={600}
              arrows={true}
              objectFit="cover"
              dots={true}
            />
            <ShortListProduct
              collectionId={
                strapiGmHomePage.selection.collections_saleor.saleor_id
              }
              title={strapiGmHomePage.selection.titre}
            />
            <BlockSalesArgument data={strapiGmHomePage.Atouts} className={""} />
            <ShortListProduct
              collectionId={
                strapiGmHomePage.affaires?.collections_saleor?.saleor_id
              }
              title={strapiGmHomePage.affaires?.titre}
            />
            <ColoredLine color={"#E9E9E9"} height={"1px"} width={"90%"} />
            <Title
              label={strapiGmHomePage.CarouselTendance.Titre}
              className={""}
            />
            <Column>
              {strapiGmHomePage.CarouselTendance.ImageLienTexte.map(item => (
                <MobileCard
                  className={isMobile && "mobile"}
                  data={item}
                  key={uuidv4()}
                />
              ))}
            </Column>
          </Wrapper>
        )}
        {!isMobile && (
          <Wrapper className={className}>
            <WraperSliderMarque>
              <Slider
                data={strapiGmHomePage.CarouselMarketing.ImageLien}
                numberOfCards={1}
                objectFit={"contain"}
                size={810}
                arrows={true}
                dots={true}
              />
            </WraperSliderMarque>
            <ColoredLine color={"#E9E9E9"} height={"1px"} width={"90%"} />
            <ShortListProduct
              collectionId={
                strapiGmHomePage.selection.collections_saleor.saleor_id
              }
              title={strapiGmHomePage.selection.titre}
            />
            <BlockSalesArgument data={strapiGmHomePage.Atouts} className={""} />
            <ShortListProduct
              collectionId={
                strapiGmHomePage.affaires?.collections_saleor?.saleor_id
              }
              title={strapiGmHomePage.affaires?.titre}
            />
            <ColoredLine color={"#E9E9E9"} height={"1px"} width={"90%"} />
            <Title
              label={strapiGmHomePage.CarouselTendance.Titre}
              className={""}
            />
            <Flex>
              {strapiGmHomePage.CarouselTendance.ImageLienTexte.map(item => (
                <Card
                  className={isMobile && "mobile"}
                  data={item}
                  key={uuidv4()}
                />
              ))}
            </Flex>
            <ColoredLine color={"#E9E9E9"} height={"1px"} width={"90%"} />
            <Title label={strapiGmHomePage.CarouselBlog.Title} className={""} />
            <BlogComponent>
              {strapiGmHomePage.CarouselBlog.pages_cms
                .slice(0, 10)
                .map(item => (
                  <Link link={item.slug} key={item.slug}>
                    <Post>
                      <ImagePost
                        className={isMobile && "mobile"}
                        data={item.Image}
                        key={uuidv4()}
                      />
                      <h3>{item.Title}</h3>
                    </Post>
                  </Link>
                ))}
            </BlogComponent>
          </Wrapper>
        )}
      </CommonWrapper>
    </Layout>
  )
}
