import React, { useState } from "react"
import { Layout } from "../../common/Layout"
import { CommonWrapper } from "../../common/CommonWrapper"
import { SEO } from "../../common/SEO"
import { useAuth } from "@saleor/sdk"
import {
  Wrapper,
  Title,
  BtnSelectInfo,
  Row,
  Logout,
  Tab,
  PlaceLogout,
} from "./PageDashboard.style"
import { Orders } from "./Orders"
import { Addresses } from "./Addresses"
import { UserInfo } from "./UserInfo"
import { useDetectDevice } from "../../../lib/useDetectDevice"

interface Props {
  className?: string
}

export function PageDashboardView({ className }: Props) {
  const { signOut } = useAuth()
  const types = ["Mes commandes", "Changer mon mot de passe", "Mes adresses"]
  const isMobile = useDetectDevice()
  const [active, setActive] = useState(types[0])
  const classNames = (className && className) + " " + (isMobile && "mobile")

  const logout = async () => {
    try {
      let res = await signOut()
    } catch (error) {
      console.error("erreur logout")
    }
  }

  return (
    <Layout>
      <SEO
        title="Espace client"
        description="Retrouvez toutes les informations relatives à votre espace client Grandes-Marques.fr telles que vos commandes effectuées et vos adresses de livraison ou de facturation."
        // isCookieYesLoaded={false}
      />
      <CommonWrapper>
        <Wrapper className={classNames}>
          <Title>Mon compte</Title>
          <Tab className={isMobile && "mobile"}>
            {types.map(type => (
              <BtnSelectInfo
                key={type}
                className={active === type && "active"}
                onClick={() => setActive(type)}
              >
                {type}
              </BtnSelectInfo>
            ))}
          </Tab>
          <Row className={isMobile && "mobile"}>
            {active == "Mes commandes" && <Orders />}
            {active == "Changer mon mot de passe" && <UserInfo />}
            {active == "Mes adresses" && <Addresses />}
          </Row>
          <PlaceLogout>
            <Logout onClick={logout}>Déconnexion</Logout>
          </PlaceLogout>
        </Wrapper>
      </CommonWrapper>
    </Layout>
  )
}
