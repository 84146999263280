import React from "react"
import { Layout } from "./../../common/Layout"
import { SEO } from "./../../common/SEO"
import { CommonWrapper } from "./../../common/CommonWrapper"
import { RegisterOrLogin } from "./../../common/RegisterOrLogin"

interface Props {
  redirectUrl?: string
}

export function PageLoginView({ redirectUrl = "/" }: Props) {
  return (
    <Layout>
      <SEO
        title="Espace client"
        description="Identifiez-vous pour accéder à votre espace client Grandes-Marques.fr et retrouver vos commandes et vos adresses."
        // isCookieYesLoaded={false}
      />
      <CommonWrapper>
        <RegisterOrLogin redirectUrl={redirectUrl} />
      </CommonWrapper>
    </Layout>
  )
}
